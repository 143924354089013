.root {
    position: fixed;
    width: 100%;
    top: 0px;
    box-sizing: border-box;
    padding: 10px 15px;
    height: 75px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 3px 20px -7px #cacaca;
    z-index: 1;
}

.profileCircle {
    width: 40px;
    height: 40px;
    background-color: #D9E3EC;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    text-transform: uppercase;
    background-repeat:no-repeat;
    background-position: center center;
    background-size: 40px;
}

.rightNav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 280px;
    color: #504F50;
}

.seperator {
    border-right: 1px solid #EDEDED;
    width: 1px;
    height: 50px;
}

.logoContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 223px;
}

.menuIcon {
    padding: 4px;
    border: solid 2px #ccc;
    border-radius: 3px;
    height: 27px;
    width: 27px;
    cursor: pointer;

    &:hover {
        background-color: #efefef;
    }
}

.notificationUnread {
    // font-weight: bold;
    // background-color: #e8e8e8 !important;
    // color: #444 !important;
}
.notificationRead {
    background-color: #fff !important;
    color: #444 !important;
}

.info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: grey;
    font-size: 13px;
    margin-top: 0.5em;
}

.customDropdownarea {
    width: 25em !important;
    overflow: auto !important;
}

.notificationCount {
    background: #d84857;
    color: #fff;
    font-weight: bold;
    border-radius: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 16px;
    font-size: 10px;
    padding: 2px 4px;
    min-width: 16px;
    width: auto;
    position: relative;
    top: -8px;
    left: -24px;
}

.notificationItem {
    // padding: 4px 8px !important;
}

.itemWrapper:focus,
.notificationItem:focus {
    outline: transparent !important;
    border: transparent !important;
}

.customNoItemDropdownarea {
    width: 12em !important;
}

.notificationSection {
    padding: 10px;
    width: 100%;
    // box-shadow: 0px 0px 3px 1px #d0d0d0;
    margin: 0px 0px;
    // border-radius: 4px;
    position: relative;
    display: flex;
    flex-flow: nowrap column;
    justify-content: space-between;
}
