@import "shared/scss//variables.scss";

@font-face {
  font-family: 'Inter';
  src: url('assets/inter.ttf'); /* IE9 Compat Modes */
  src: url('assets/inter.ttf')  format('truetype'), /* Safari, Android, iOS */
}

body {
  margin: 0;
  font-family: 'Inter',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background-color: #F5F5F5;
  font-size: $fontSize;
  background-color: $bgColor;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-center{
  text-align: center;
}

h1,h3,h3,h4,h5,h6 {
  margin: 0px;
  margin-bottom: 5px;
}

hr {
  border: none;
    border-top: solid 1px #e4e4e4;
}

.link,
a.link {
  cursor: pointer;
  color: #222222;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.link-underline {
  cursor: pointer;
  color: #222222;
  text-decoration: underline;
}

.link-blue {
  cursor: pointer;
  color: #43668B;

  &:hover {
    text-decoration: underline;
  }
}

.link-red {
  cursor: pointer;
  color: #D84757;

  &:hover {
    text-decoration: underline;
  }
}

a.small {
  font-size: 14px;
}


.timeline-Header,
.timeline-Footer {
  display: none !important;
}

footer {
  display: none !important;
}