@import "shared/scss/variables.scss";

.inner {
    display: flex;
    align-items: center;
}

.root {
    display: inline-block;
    background-color: $primary;
    border: none;
    padding: 12px 15px;
    border-radius: 4px;
    color: white;
    font-size: $fontSize;
    cursor: pointer;
    // display: flex;
    // align-items: center;

    &:hover {
        background-color: lighten($primary,10%);
    }
}

.small {
    padding: 6px 7px;
}

.normal {
    padding: 12px 25px;
}

.disabled {
    background-color: #c5c5c5;
    &:hover {
        background-color: lighten(#c5c5c5,10%);
    }
}

.primary {
    background-color: $primary;
    &:hover {
        background-color: lighten($primary,10%);
    }
}

.link {
    background-color: transparent;
    // text-decoration: underline;
    border-radius: 0px;
    color: #248cd8;
    &:hover {
        background-color: transparent;
    }
}

.linkDanger {
    background-color: transparent;
    // text-decoration: underline;
    border-radius: 0px;
    color: $red;
    &:hover {
        background-color: transparent;
    }
}

.secondary {
    background-color: $secondary;
    &:hover {
        background-color: lighten($secondary,10%);
    }
}

.secondary2 {
    background-color: #A0A0A0;
    &:hover {
        background-color: lighten(#A0A0A0,10%);
    }
}

.cancel {
    background-color: #A0A0A0;
    &:hover {
        background-color: lighten(#A0A0A0,10%);
    }
}


.danger {
    background-color: $red;
    &:hover {
        background-color: lighten($red,10%);
    }
}

.success {
    background-color: $green;
    &:hover {
        background-color: lighten($green,10%);
    }
}