.root {
    background-color: white;
    width: 100%;
    padding: 20px;
    margin-top: 15px;
    margin-right: 15px;
    border-radius: 6px;
    box-shadow: 0px 0px 15px -3px #dadada;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}