@import "shared/scss/variables.scss";

$textColor:#D84956;//#ffffff; //#333;
$red: #D84956;

.root {
    background-color: white;//$primary;//white;
    width: 250px;
    height:100%;
    margin: 0px;
    padding: 0px;
    color: $primary;
    overflow-y: auto;
    transition: width .5s, height 4s;
}

ul.menu {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    color: $textColor;

    li {
        padding: 15px 20px;
        // border-bottom: solid 1px #C6464D;
        display: flex;
        align-items: center;
        cursor: pointer;
        user-select: none;

        a {
            color: $textColor;
            text-decoration: none;
            display:flex;
            align-items: center;
        }

        &.selected {
            background-color: $red;
            color: white;
            cursor: default;
            a {
                color: white;
            }
        }
    }
}

.menuIcon {
    margin-right: 10px;
}