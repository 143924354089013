.root {

}

.content {
    position: fixed;
    top: 76px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    display: flex;
    z-index: 0;
}

.innerContent {
    padding: 10px 20px;
    width: 100%;
    overflow-y: auto;
}