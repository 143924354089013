.root {

}

.header {
    font-weight: bold;
    font-size: 16px;
}

.link {
    color: #d84856;
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;

    &:hover {
        color: lighten(#d84856,10%);
    }
}

.search_input {
    z-index: 1;
    box-sizing: border-box;
    border: 1px solid #ccc;
    font-family: inherit;
    font-size: 12px;
    line-height: 1.28571429;
    height: 40px;
    border-radius: 3px;
    padding: 4px;
    outline: 0;
    border-radius: 4px;
    position: absolute;
    margin: 1em;
    width: 18em;
}

.select_location_combo {
    z-index: 1;
    box-sizing: border-box;
    border: 1px solid #ccc;
    font-family: inherit;
    font-size: 12px;
    line-height: 1.28571429;
    height: 40px;
    border-radius: 3px;
    outline: 0;
    position: absolute;
    margin: 1em;
    width: 18em;
    left: 25%;
}


@media screen and (min-width:768px)and (max-width: 1168px) {
    .select_location_combo{
        left: 60%; 
    }
  }
  @media screen and (min-width:360px) and (max-width: 767px) {
    .select_location_combo{
        margin-top: 62px;
        margin-left: 83px;
        width: 110px;
       
    }
    .search_input{
        width: 108px;       
    }
  }

  