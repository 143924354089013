.root {
    
    display: inline-block;
}

.container {
    background-color: white;
    width: 230px;
    padding: 20px;
    // margin-top: 15px;
    // margin-right: 15px;
    border-radius: 6px;
    box-shadow: 0px 0px 15px -3px #dadada;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.number {
    font-size: 30px;
    margin-top: 20px;
}

.label {
    color: #222222;
    font-size: 14px;
    font-weight: bold;
}

.icon {
    margin-top: 10px;
    margin-bottom: 10px;
    // background-color: #dffaf5;
    // color: #6ADABF;
    // background-color: #d9e3ec;
    background-color: white;
    color: #D84956;
    /* padding: 10px; */
    border-radius: 50%;
    border: solid 1px #D84956;
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    font-size: 23px;
    justify-content: center;
}