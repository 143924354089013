$red: #d84857;
$primary: #d84857;//#4089E3;//#d84857;
$primaryHover: lighten(#d84857,20%);//#d84857;

$secondary: #43658b;//#30475e;//#4e89ae;//#43658b;
$secondaryHover: #537dac;//#30475e;//#4e89ae;//#43658b;

$danger: #d9534f;
$dangerHover: #ff5a55;


$bgColor: #ffffff; // #F5F5F5
$bgContrastColor: #F5F5F5;
$bgContrastColor2: #e5e5e5;
//4e89ae
// darker option 30475e

$fontSize: 15px;
$green: #4BB543;