.root {
    padding: 5px 0px;
    width: 100%;
    // margin-right: 10px;
    // margin-left: 10px;
}

.label {
    padding: 10px 0px;
    font-weight: bold;
}

.horizontal {
    display:flex;
    align-items: flex-start;
    justify-content: space-between;
}