.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Drag icon for the grid row */
td.drag-icon::before {
  content: "" !important;
  border-left: 4px dotted rgb(204, 204, 204) !important;
  display: block !important;
  height: 20px !important;
  margin: 15px 3px !important;
  cursor: grab !important;
}

/* Set the dropdown option max height */
.css-4ljt47-MenuList {
  max-height: 10em !important;
}
/* Make the warning icon width/height smaller */
.swal-icon--warning__body {
  height: 15px !important;
}
/* Make the warning icon width/height smaller */
.swal-icon {
  width: 40px !important; 
  height: 40px !important;
}
/* Alert style for delete  */
.swal-title {
  font-size: 15px !important;
  font-weight: bolder !important;
  color:black;
  font-family: 'Inter',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
.swal-button {
  font-size: 12px !important;
  padding: 10px 17px !important;
}
.swal-overlay--show-modal .swal-modal {
  width: 399px !important;

}
.para{
  color:#A9A9A9;
  line-height: 1.2em;
  word-break: break-word;
  overflow-wrap: break-word;

}
.dot{
  color:#d84857
}
.headerNotification{
  color: black;
  position: relative;
}
::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3) !important;
	border-radius: 10px !important;
	background-color:white !important;
 }

 ::-webkit-scrollbar
{
	width: 8px;
  height: 10px;
	background-color:white !important;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px !important;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3) !important;
	background-color:#d84857 !important;
}

.ql-editor{
  min-height:150px;
}