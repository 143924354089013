.root {

}

.quickWidgetsHolder {
    display:flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.quickActionsHolder {
    width: 350px;
}

.widgets {
    display:flex;
    align-items: baseline;
    justify-content: space-between;
    width: 820px;
}

.col{

    width: 45%;
    
    padding: 0px;
    margin-top: 0px;
    margin-right: 15px;

    &.shadow {
        padding: 10px;
        border-radius: 6px;
        box-shadow: 0px 0px 15px -3px #dadada;
    }
}

.colfull{

    width: 100%;
    
    padding: 0px;
    margin-top: 0px;
    margin-right: 15px;

    &.shadow {
        padding: 10px;
        border-radius: 6px;
        box-shadow: 0px 0px 15px -3px #dadada;
    }
}
.cols{
    width: 40%;   
    height: 50%;
    box-shadow: 0px 0px 15px -3px #dadada;
    padding: 30px;
    margin-top: 55px;
    margin-right: 105px;
    margin-bottom: 10px;
}
.flexNotification{
    display: flex;
    align-items: center;
    line-height: 8px;
    margin: 1em 1em;
    
}
.flex , .homeFlex{
    display: flex;
    justify-content: space-between;

}
 
h5, h3{
    color:#d84857
}

.myEvents{
    border-radius: 6px;
    box-shadow: 0px 0px 15px -3px #dadada;
    padding: 20px;
    margin-top: 35px;
    margin-right: 15px;
    width:100%;
    overflow:auto
    
}
.text{
    font-size: 50px;
    margin-top: 10px;
    color: #d84857;
    margin-left: -30px;
}
.imag{
    margin-left: 10px;
}
.NotificationContainer{
    margin-left: 20px;
}
.plus{
   color: #d84857;
   font-size: 25px;
   font-weight: bold;
   margin-right: 6px;
}

.notificationList {
    margin-top: 10px;
    max-height: 390px;
    overflow-y: auto;
    overflow-x: hidden;

    ul {
        margin: 0px;
        padding: 0px;
    }
}

@media only screen and (max-width: 1169px) {
    .homeFlex{
        display: inline-block;
        width: 100%;
     
    }
    .flexNotification{
        justify-content: space-between;
    }
  }
  
  @media screen and (max-width:768px){
    .cols{
        width: 45% !important;   
      
    }
    .col{
        width: 76%;
    }
    .NotificationContainer{
        margin-right: 40px;
     }
  }
  @media screen and (max-width:360px){
    .cols{
        width: 100% !important;   
    }
    .col{
        width: 370px !important;
        height: 300px;
    }
    .text{
        font-size: 50px;
        margin-top: 10px;
        color: #d84857;
        margin-left: -5px;
      }
    .NotificationContainer{
       margin-right: 80px;
    }
    .imag{
        margin-left: 25px;
    }
    .flexNotification{
        margin: 1em -3em;
    }
  }
 